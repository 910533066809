<template>
  <div>
    <section v-if="$auth.db && $auth.db.family">
      <b-container>
        <h1>Abonnement</h1>
        <b-card>
          <div>
            <strong>Abonnement</strong>
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <div class="mb-1 d-flex flex-column justify-content-around">
                <div class="mb-1">
                  <span class="d-block d-md-inline">Aantal familieleden:</span>
                  <span class="amount-children ml-md-1">{{ $auth.db.family.children }}</span>
                  <span v-if="$auth.db.outstanding.length == 0">
                    <b-button variant="primary" class="ml-1 mr-1" @click="subChild()">-</b-button>
                    <b-button variant="primary" class=" mr-1" @click="addChild(10)">+</b-button>
                  </span>
                  <InfoButton :bg-blue="true" id="number-children" text="Dit is het maximale aantal familieleden dat je momenteel kunt toevoegen. Wil je meer familieleden toevoegen, klik dan op het plusje en voldoe de betaling." />
                </div>
                <div>Abonnementsduur: <strong>{{ $auth.db.family.contract_type === 0 ? "Maandelijks" : "Jaarlijks" }}</strong></div>
                <span v-if="$auth.db.outstanding.length == 0">
                  <b-button v-if="changed" :variant="$auth.db.family.children - initialChildren > 0 ? 'primary' : 'warning'" class="mt-1 mr-1" @click="addChildren()">{{ $auth.db.family.children - initialChildren > 0 ? "Naar betalen" : "Aanpassen" }}</b-button>
                </span>
              </div>
              <div class="d-sm-none">Totaal: <strong>{{ f.format(price_discount) }} per {{ $auth.db.family.contract_type === 0 ? "maand" : "jaar" }}</strong></div>
              <div v-if="new Date($auth.db.family.created_at) > new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)" class="d-sm-none">Na eerste jaar <strong>{{ f.format(price_discount * 0.1) }} per {{ $auth.db.family.contract_type === 0 ? "maand" : "jaar" }}</strong></div>
              <div class="d-none d-sm-block pricing font-weight-bold">
                <div style="font-size: 2em;">Totaal</div>
                <div>
                  <span style="font-size:3em;">{{ f.format(price_discount) }}</span> <span style="font-size: 2em;">per {{ $auth.db.family.contract_type === 0 ? "maand" : "jaar" }}</span>
                  <div v-if="new Date($auth.db.family.created_at) > new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)" style="font-size:1.5em;" class="d-flex flex-row font-weight-normal mt-1"><span>Na eerste jaar {{ f.format(price_discount * 0.1) }} per {{ $auth.db.family.contract_type === 0 ? "maand" : "jaar" }}</span></div>
                </div>
              </div>
            </div>

          </div>
          <b-row v-if="$auth.db.outstanding.length > 0">
            <b-col class="text-center pl-5 pr-5 mt-2">
              <div class="bg-danger text-white rounded p-1">
                Je dient eerst openstaande bedragen - betreffende registratie en het toevoegen van familieleden - te hebben betaald, alvorens familiebank te mogen gebruiken.
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <strong>Facturen</strong>
            </b-col>
          </b-row>
          <b-table class="mt-2" responsive borderless :items="invoices" :fields="fields">

            <template #cell(sent_date)="row">
              {{ new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(row.item.sent_date.replace(/-/g, '/').replace('T', ' '))) }}
            </template>

            <template #cell(open)="row">
              <b-badge pill :variant="row.item.open == 0 ? 'success' : 'warning'">
                {{ row.item.open == 0 ? "Betaald" : "Open" }}
              </b-badge>
            </template>

            <template #cell(action)="row">
              <div class="btn-group">
                <b-button class="btn" @click="downloadInvoice(row.item.hashid)" download variant="info">
                  Bekijk factuur
                </b-button>
                <b-button class="btn" v-if="row.item.open > 0 && !row.item.periodic" @click="payInvoice(row.item.hashid)" variant="primary">
                  Betaal factuur
                </b-button>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-container>
    </section>
  </div>
</template>

<script>
// import store from '@/store'

import { mapActions } from "vuex"

export default {
  components: {
  },
  data() {
    return {
      subscription: {
        amount: 15,
      },
      changed: false,
      initialChildren: 0,
      invoices: [],
      user: {
        child: [],
        partner: [],
        partnerSelected: null,
        partnerOptions: [{ title: 'Isabelle' }, { title: 'Erik' }, { title: 'Homppo' }, { title: 'Mieppo' }],
        login: { 0: false },
      },
      fields: [
        { key: 'invoice_number', label: 'Factuurnummer' },
        { key: 'sent_date', label: 'Datum' },
        { key: 'open', label: 'Status' },
        { key: 'action', label: '' },
      ],
      f: new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' })
    }
  },
  computed: {
    price() {
      return ((7.5 + this.$auth.db.family.children * 2.499) * (this.$auth.db.family.contract_type ? 10 : 1)).toFixed(2);
    },
    price_discount() {
      let p = this.price;
      if (this.$auth.db.family.partnercode_object && this.$auth.db.family.partnercode_object.recurring) {
        if (this.$auth.db.family.partnercode_object.type === 1) {
          if (p < this.$auth.db.family.partnercode_object.value) {
            p = 0;
          } else {
            p -= this.$auth.db.family.partnercode_object.value
          }
        }
        if (this.$auth.db.family.partnercode_object.type === 0) {
          p = p - (p * this.$auth.db.family.partnercode_object.value / 100);
        }
      }

      if (new Date(this.$auth.db.family.created_at) < new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)) {
        p = p * 0.1
      }
      return p;
    }
  },
  mounted() {
    this.setLoading("invoices");
    let _this = this;
    this.$http.get("/invoices").then((response) => {
      this.invoices = response.data.invoices;
    }).finally(() => {
      _this.doneLoading("invoices");
    });
  },
  methods: {
    ...mapActions({ setLoading: "loading/set", doneLoading: "loading/done" }),
    editUser() {
      this.$bvModal.show('editUser')
    },
    payInvoice(hashid) {
      let _this = this;
      this.$http.post("/invoice/checkout", {
        hashid: hashid
      }).then(response => {
        if (response.data.invoice) {
          _this.$router.push({ name: "overview" });
        } else {
          window.location = response.data.checkoutUrl;
        }
      });
    },
    downloadInvoice(hashid) {
      this.$http.get("/invoices/download", { params: { hashid: hashid }, responseType: 'blob' }).then(response => {
        console.log(response);
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'invoice.pdf'
        link.click()
      });
    },
    addChild(max) {
      if (!this.changed) {
        this.changed = true;
        this.initialChildren = this.$auth.db.family.children;
      }
      this.$auth.db.family.children < max ? this.$auth.db.family.children += 1 : 10;
      if (this.$auth.db.family.children === this.initialChildren) {
        this.changed = false;
      }
    },
    subChild() {
      if (this.$auth.db.family.children > 0 && this.$auth.db.family.children > this.$auth.db.family.total_children) {
        if (!this.changed) {
          this.changed = true;
          this.initialChildren = this.$auth.db.family.children;
        }
        this.$auth.db.family.children -= 1;
        if (this.$auth.db.family.children === this.initialChildren) {
          this.changed = false;
        }
      }
    },
    addChildren() {
      let _this = this;
      this.setLoading("set_children");
      this.$http.post("/family/children", {
        children: this.$auth.db.family.children,
        _method: "PATCH"
      }).then(response => {
        if (response.data.checkoutUrl) {
          window.location = response.data.checkoutUrl;
        } else {
          _this.initialChildren = response.data.children;
          _this.changed = false;
          _this.doneLoading("set_children");
        }
      }).catch(() => {
        _this.doneLoading("set_children");
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.pricing {
  color: #5E5873;
  line-height: 1.2;
}

.amount-children {
  padding: 0.7em 2.5em;
  background-color: #EFEFEF;
  border-radius: 15px;
}
</style>
